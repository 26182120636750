import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { PageLinks, ParseFolders } from "./utils"
import { Splash, Login, Informations, CreateManualTreatment, Affairs } from "./sections";
import { Navbar, Alertes, Synoptique } from "./components"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { updateSocketStatus, setLastSocketPong, setAlreadyAskedForFolders, setSituations, setAlert, setError, setCurrentAffair } from "./store"
import { io } from "socket.io-client";
import { useEffect } from "react";

const socket = io(process.env.REACT_APP_API_URL, { rejectUnauthorized: false, transports: ['websockets', 'polling'] });

const SINAPSE = () => {

    const isRunning = useSelector((Store) => Store.SINAPSE.isRunning);
    const isLoggedIn = useSelector((Store) => Store.SINAPSE.isLoggedIn);

    const dossiers = useSelector((Store) => Store.SINAPSE.dossiers);
    const alerts = useSelector((Store) => Store.SINAPSE.alerts);
    const userInformations = useSelector((Store) => Store.SINAPSE.userInformations);
    const token = useSelector((Store) => Store.SINAPSE.token);
    const areFoldersParsed = useSelector((Store) => Store.SINAPSE.areFoldersParsed);
    const currentAffair = useSelector((Store) => Store.SINAPSE.currentAffair);

    const currentFolder = useSelector(Store => Store.SINAPSE.currentFolder);
    const alreadyAskedForFolders = useSelector(Store => Store.SINAPSE.alreadyAskedForFolders);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log(socket);

        socket.on('connect', (s) => {
            console.log(s);

            if (isRunning && isLoggedIn && !Object.keys(dossiers).length && token && !alreadyAskedForFolders && userInformations !== {}) {
                socket.emit('SINAPSE_ASK_FOR_FOLDERS', {
                    jwt: token
                })
                socket.emit('SINAPSE_GET_SITUATIONS_GESTURES', {
                    jwt: token
                })
                dispatch(setAlreadyAskedForFolders(true))
            }
            dispatch(updateSocketStatus({
                socketRunning: true,
                socketId: socket.id
            }))
        });

        socket.on("ERROR", (data) => {
            console.log(data)
            dispatch(setError({ type: "alert", message: data.message, active:true }))
        })

        socket.on("connect_error", () => {
            // revert to classic upgrade
            socket.io.opts.transports = ["polling", "websocket"];
        });

        socket.on("SINAPSE_FOLDERS_ACTUALISATION", (data) => {
            ParseFolders(data, dispatch, userInformations)
        })

        socket.on("SINAPSE_SITUATIONS_GESTURES", (data) => {
            dispatch(setSituations(data))
        })

        socket.on("SINAPSE_NEW_ALERT", () => {
            socket.emit('SINAPSE_ASK_FOR_FOLDERS', {
                jwt: token
            })
        })

        socket.on('disconnect', () => {
            dispatch(updateSocketStatus({
                socketRunning: false,
                socketId: null
            }))
        });

        socket.on('pong', () => {
            dispatch(setLastSocketPong(new Date().toISOString()))
        });

        /*return () => {
            socket.off('pong');
        };*/
    }, [userInformations]);

    useEffect(() => {
        if (isRunning && isLoggedIn && !Object.keys(dossiers).length && token && !alreadyAskedForFolders && userInformations !== {}) {
            socket.emit('SINAPSE_ASK_FOR_FOLDERS', {
                jwt: token
            })
            socket.emit('SINAPSE_GET_SITUATIONS_GESTURES', {
                jwt: token
            })
            dispatch(setAlreadyAskedForFolders(true))
        }
    }, [isRunning, isLoggedIn, dossiers, token, userInformations])


    return (
        <>
            {
                !isRunning ? <Splash /> :
                    (!isLoggedIn || !areFoldersParsed) ? <Login /> :
                        <>
                            <Navbar />
                            <Synoptique socket={socket} token={token} />
                            <Alertes socket={socket} />

                            <Routes>
                                <Route path={PageLinks.Main} element={
                                    (Object.entries(currentFolder).length) ? <Navigate replace to={PageLinks.Informations} /> : <></>
                                } />
                                <Route path={PageLinks.Informations} element={
                                    (!Object.entries(currentFolder).length || !areFoldersParsed) ? <Navigate replace to={PageLinks.Main} /> : <Informations socket={socket} />
                                } />
                                <Route path={PageLinks.Affairs} element={
                                    (!Object.entries(currentAffair).length || !areFoldersParsed) ? <Navigate replace to={PageLinks.Main} /> : <Affairs socket={socket} />
                                } />
                                <Route path={PageLinks.CreateManualTreatment} element={<CreateManualTreatment socket={socket} />} />
                            </Routes>

                            {
                                <Snackbar open={!!Object.keys(alerts).length} autoHideDuration={6000} onClose={() => {
                                    dispatch(setAlert({}))
                                }}>
                                    <Alert onClose={() => {
                                        dispatch(setAlert({}))
                                    }} severity={alerts.type} sx={{ width: '100%' }}>
                                        {alerts.message}
                                    </Alert>
                                </Snackbar>
                            }
                        </>

            }
        </>
    )
}

export default SINAPSE