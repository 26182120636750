import logoSinapse from "../assets/SINAPSE.png"

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUserInformations } from "../store"

import OutlinedInput from '@mui/material/OutlinedInput';

import OutlinedButton from "../components/OutlinedButton"
import { Alert } from "@mui/material";

import axios from "axios";
import { APILinks } from "../utils/PageLinks";


const sendLogin = (username, password, setError, dispatch, setAlreadyFetched) => {
    axios.post(APILinks.login, {
        username: username,
        password: password
    }).then(result => {
        if (result.data.ack === "SUCCESS") {
            sessionStorage.setItem('jwt', result.data.token);
            setAlreadyFetched(true)
            dispatch(loadUserInformations({
                userInformations: {
                    ...result.data.userInformations
                },
                instanceInformations: {
                    ...result.data.instanceInformations
                },
                token: result.data.token,
            }))
            // window.location.reload();
        } else {
            console.log("ERR", result.data)
            setError(result.data.message)
            setAlreadyFetched(false)
        }
    }).catch(err => {
        console.log("ERR", err)
        setError("Une erreur de connexion est survenue.")
        setAlreadyFetched(false)
    })
}


const Login = () => {

    const dispatch = useDispatch();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(false);
    const [alreadyFetched, setAlreadyFetched] = useState(false)
    const [callForFetch, setCallForFetch] = useState(false)
    const errorValue = useSelector(Store => Store.SINAPSE.error)

    useEffect(() => {
        if (callForFetch) {
            sendLogin(username, password, setError, dispatch, setAlreadyFetched)
        }
    }, [callForFetch])

    return (<div className="login">
        <img src={logoSinapse} alt="SINAPSE™" className="login__logo" />
        <h1>Connectez-vous à SINAPSE™.</h1>
        <div className="form">
            <OutlinedInput
                type="email"
                placeholder="Identifiant"
                className='textInput'
                onChange={(value) => setUsername(value.target.value)}
            />
            <OutlinedInput
                type="password"
                placeholder="Mot de passe"
                className='textInput'
                onChange={(value) => setPassword(value.target.value)}
            />
        </div>
        <OutlinedButton filled={true} onClick={() => {
            if (!alreadyFetched) {
                setCallForFetch(true)
            }
        }}>Me connecter</OutlinedButton>

        {
            error ? <Alert severity="error">{error}</Alert> : null
        }
        {
            errorValue.active ? <Alert severity="error">{errorValue.message}</Alert> : null
        }
    </div>)
}

export { Login }