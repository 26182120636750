import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { getGravity } from '../utils';
import { setCurrentFolder } from '../store';

import Badge from '@mui/material/Badge';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import OutlinedButton from './OutlinedButton';


const Alertes = (props) => {

    const socket = props.socket

    const [isOpened, setOpened] = useState(false);
    const parsedFolders = useSelector(Store => Store.SINAPSE.parsedDossiers)
    const token = useSelector((Store) => Store.SINAPSE.token);
    const alertLength = Object.entries(parsedFolders.alerts).length;
    const alerts = [];

    if (alertLength) {
        Object.entries(parsedFolders.alerts).forEach(([id, alert]) => {
            alerts.push(
                <div className='alerts-item' key={id}>
                    <div className={'gravityTag ' + getGravity(alert)}></div>
                    <div className='content'>
                        <span className='situation'>{alert.diagnosis_details.situation.name}</span>
                        <span className='place'>{alert.city + " - " + alert.state}</span>
                    </div>

                    <OutlinedButton filled={true} complementaryClassName='alerts-item--btn' onClick={() => {
                        socket.emit("SINAPSE_ALERTS_INITAL_AFFECTATION", {
                            id: alert.id,
                            type: alert.type,
                            jwt: token,
                        })
                    }}>Ouvrir</OutlinedButton>
                </div>
            )
        })
    }

    return (
        <div className={isOpened ? "alerts opened" : "alerts"}>
            <div className="alerts__header">
                <KeyboardDoubleArrowLeftIcon sx={{ fontSize: 22 }} className="open" onClick={() => { setOpened(!isOpened) }} />
            </div>
            <div className='alerts__notification'>
                {
                    alertLength ? (
                        <Badge badgeContent={alertLength} color="primary">
                            <WarningAmberOutlinedIcon />
                        </Badge>
                    ) : <WarningAmberOutlinedIcon />
                }
            </div>
            <div className='alerts__body'>
                {
                    alerts
                }
            </div>
        </div>
    )
}

export default Alertes