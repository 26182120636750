import { createTheme } from '@mui/material/styles';

const CustomComponentStyle = createTheme({
    palette: {
        primary: {
            main: '#161667',
        },
        secondary: {
            main: '#101828',
            light: '#d0d5dd',
            dark: '#101828',
            contrastText: '#101828',
            grey700: '#344054'
        },
        lightGrey: {
            main: "#667085"
        },
        white: {
            main: '#ffffff'
        },
        alert: {
            main: '#e22323'
        },
        warning: {
            main: '#f79009',
            light: '#fedf89'
        },
        green: {
            light: '#a8d6b4'
        }
    },
    typography: {
        fontFamily: [
            'Rubik',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export { CustomComponentStyle }