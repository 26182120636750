const OutlinedButton = (props) => {
    let className = props.white ? 'outlinedButton white' : 'outlinedButton'
    props.filled ? className += " filled" : className += "";
    props.complementaryClassName ? className += " " + props.complementaryClassName : className += "";
    props.fullwidth ? className += " fullwidth" : className += "";
    props.disabled ? className += " disabled" : className += "";
    props.leftIcon ? className += " leftIcon" : className += "";
    props.whiteFilled ? className += " whiteFilled" : className += "";
    props.small ? className += " small" : className += "";

    let reProps = { ...props }
    delete reProps.filled
    delete reProps.white
    delete reProps.complementaryClassName
    delete reProps.fullwidth
    delete reProps.leftIcon
    delete reProps.whiteFilled
    delete reProps.small

    return (
        props.href ?
            <a className={className} {...reProps}>{props.children}</a>
            :
            <button className={className} {...reProps}>{props.children}</button>
    )
}

export default OutlinedButton;