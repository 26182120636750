
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Checkbox } from '@mui/material';
import { getGravity } from '../utils';
import moment from 'moment';
import SynoptiqueStatusChip from './SynoptiqueStatusChip';

import { useDispatch } from 'react-redux';
import { setCurrentFolder, setCurrentAffair } from '../store';

import { AsYouType } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';

const SynoptiqueDossiers = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    let dossiers = props.dossiers
    let dossiersData = []
    let closed = props.closed ? " closed" : ""

    moment.locale();

    Object.entries(dossiers).forEach(([id, dossier]) => {
        dossiersData.push(
            <div className='item' key={id} onClick={() => {
                if (!props.isCreatingAffairs) {
                    if (dossier.type) {
                        dispatch(setCurrentFolder({
                            type: dossier.type,
                            closed: props.closed ?? false,
                            id: id,
                        }))
                        props.setOpened(false)
                    } else {
                        dispatch(setCurrentAffair({
                            type: 'alert',
                            id: id,
                        }))
                        navigate('/affaire')
                        props.setOpened(false)
                    }
                }
            }}>
                <div className={'gravityTag ' + getGravity(dossier, props.affair) + closed}></div>
                <div className='content'>
                    <span className='situation'>{dossier.diagnosis_details ? dossier.diagnosis_details.situation.name : (dossier.situation_diagnosis ? dossier.situation_diagnosis.name ? dossier.situation_diagnosis.name : "" : "")}</span>
                    <span className='place'>{dossier.city ? dossier.city + " - " + dossier.state : ""}</span>
                </div>
                <span className='date'>Démarré à <b>{moment(dossier.launch_time).format('HH:mm')}</b></span>
                <span className='requerant'>{
                    dossier.incident_details ?
                        (Object.values(dossier.incident_details.userDetails).length ? (dossier.incident_details.userDetails.firstName + " " + dossier.incident_details.userDetails.lastName) : '') : ''
                }</span>
                <span className='phone'>{
                    dossier.incident_details ?
                        (Object.entries(dossier.incident_details.userDetails).length ? (new AsYouType('FR').input(dossier.incident_details.userDetails.phone)) : dossier.rescuer_phone ? (new AsYouType('FR').input(dossier.rescuer_phone)) : '') : ''
                }</span>

                {
                    props.isCreatingAffairs ?
                        <Checkbox
                            style={{ order: 2 }}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    let d = { ...props.selectedDossiers }
                                    d[id] = { ...dossier }
                                    props.setSelectedDossiers(d)
                                } else {
                                    if (props.selectedDossiers[id]) {
                                        let d = props.selectedDossiers
                                        delete d[id]
                                        props.setSelectedDossiers(d)
                                    }
                                }
                            }}
                        />
                        : <SynoptiqueStatusChip status={dossier.support_details ? dossier.support_details.status : (dossier.treatment_details ? dossier.treatment_details.status : 'ONGOING')} />
                }

                <div className='openFolder' >
                    <VisibilityOutlinedIcon fontSize="small" color="lightGrey" />
                </div>
            </div>
        )
    })

    return (
        <>
            {
                dossiersData
            }
            {
                !Object.entries(dossiers).length ? (
                    <span className='noFolder'>Aucun dossier</span>
                ) : null
            }
        </>
    )
}

export default SynoptiqueDossiers;