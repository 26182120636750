const PageLinks = {
    Main: "/",
    Informations: "/informations",
    CreateManualTreatment: "/appel",
    Affairs: "/affaire",
};

const defaultAPIPath = process.env.REACT_APP_API_URL

const APILinks = {
    login: defaultAPIPath + "/login"
}

export { PageLinks, APILinks, defaultAPIPath }