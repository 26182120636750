import { useState } from 'react'
import { useDispatch } from 'react-redux';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { setAlert, setDisplayedFolders } from '../store';

import SynoptiqueDossiers from './SynoptiqueDossiers';
import OutlinedButton from './OutlinedButton';

const sortFolders = (search, folders, allFolders, setFolders) => {
    if (search === "") {
        folders = allFolders;
    } else {
        folders = [];

        folders.userUrgences = Object.values(allFolders.userUrgences)
        folders.userManualTreatments = Object.values(allFolders.userManualTreatments)
        folders.userAffairs = Object.values(allFolders.userAffairs)
        folders.pastUserFolders = Object.values(allFolders.pastUserFolders)
        folders.serviceUrgences = Object.values(allFolders.serviceUrgences)
        folders.serviceManualTreatments = Object.values(allFolders.serviceManualTreatments)
        folders.serviceAffairs = Object.values(allFolders.serviceAffairs)
        folders.pastServiceFolders = Object.values(allFolders.pastServiceFolders)

        folders.userUrgences = folders.userUrgences.filter(r => {
            let result = { ...r }
            return isItemInSearchQuery(result, search);
        });
        folders.userManualTreatments = folders.userManualTreatments.filter(r => {
            let result = { ...r }
            return isItemInSearchQuery(result, search);
        });
        folders.userAffairs = folders.userAffairs.filter(r => {
            let result = { ...r }
            return isItemInSearchQuery(result, search);
        });
        folders.pastUserFolders = folders.pastUserFolders.filter(r => {
            let result = { ...r }
            return isItemInSearchQuery(result, search);
        });
        folders.serviceUrgences = folders.serviceUrgences.filter(r => {
            let result = { ...r }
            return isItemInSearchQuery(result, search);
        });
        folders.serviceManualTreatments = folders.serviceManualTreatments.filter(r => {
            let result = { ...r }
            return isItemInSearchQuery(result, search);
        });
        folders.serviceAffairs = folders.serviceAffairs.filter(r => {
            let result = { ...r }
            return isItemInSearchQuery(result, search);
        });
        folders.pastServiceFolders = folders.pastServiceFolders.filter(r => {
            let result = { ...r }
            return isItemInSearchQuery(result, search);
        });

        var foldersObject = {
            userUrgences: {},
            userManualTreatments: {},
            userAffairs: {},
            pastUserFolders: {},
            serviceUrgences: {},
            serviceManualTreatments: {},
            serviceAffairs: {},
            pastServiceFolders: {},
        }

        folders.userUrgences.forEach(item => {
            foldersObject.userUrgences[item.id] = item;
        })
        folders.userManualTreatments.forEach(item => {
            foldersObject.userManualTreatments[item.id] = item;
        })
        folders.userAffairs.forEach(item => {
            foldersObject.userAffairs[item.id] = item;
        })
        folders.pastUserFolders.forEach(item => {
            foldersObject.pastUserFolders[item.id] = item;
        })
        folders.serviceUrgences.forEach(item => {
            foldersObject.serviceUrgences[item.id] = item;
        })
        folders.serviceManualTreatments.forEach(item => {
            foldersObject.serviceManualTreatments[item.id] = item;
        })
        folders.serviceAffairs.forEach(item => {
            foldersObject.serviceAffairs[item.id] = item;
        })
        folders.pastServiceFolders.forEach(item => {
            foldersObject.pastServiceFolders[item.id] = item;
        })

        folders = foldersObject
    }

    setFolders(folders)
}

const Synoptique = (props) => {

    const dispatch = useDispatch()

    const parsedDossiers = useSelector((Store) => Store.SINAPSE.parsedDossiers);
    const areFoldersParsed = useSelector((Store) => Store.SINAPSE.areFoldersParsed);

    const folders = useSelector((Store) => Store.SINAPSE.displayedFolders);

    console.log('_-_-_-_ < DEBUG >  _-_-_-_')
    console.log("Le serveur a renvoyé : ")
    console.log(`${Object.keys(folders.userUrgences)?.length} urgences`);
    console.log(`${Object.keys(folders.userManualTreatments)?.length} traitements manuels`);
    console.log(`${Object.keys(folders.userAffairs)?.length} affaires`);
    console.log(`${Object.keys(folders.pastUserFolders)?.length} dossiers transmis`);
    console.log('_-_-_-_ < / DEBUG >  _-_-_-_')


    const setFolders = (x) => dispatch(setDisplayedFolders(x));

    const [isOpened, setOpened] = useState(false)
    const [isCreatingAffairs, setCreatingAffairs] = useState(false);
    const [selectedDossiers, setSelectedDossiers] = useState({})
    const [searchTerms, setSearchTerms] = useState("")
    const [currentTab, setCurrentTab] = useState("personalItems")

    const handleTabChange = (event, value) => {
        setCurrentTab(value);
    };

    if (areFoldersParsed) {

        return (
            <div className={isOpened ? "synoptique opened" : "synoptique"}>
                <div className="synoptique__header">
                    <div className="synoptique__header-title">
                        <span className="title">Synoptique</span>
                        <KeyboardDoubleArrowRightIcon sx={{ fontSize: 22 }} className="open" onClick={() => {
                            setOpened(!isOpened)
                            setCreatingAffairs(false)
                        }} />
                    </div>
                    <OutlinedInput
                        type="text"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        placeholder="N° de téléphone, incident..."
                        className='search'
                        value={searchTerms}
                        onChange={(t) => {
                            setSearchTerms(t.target.value)
                            sortFolders(t.target.value, folders, parsedDossiers, setFolders)
                        }}
                    />
                </div>

                <TabContext value={currentTab}>
                    <TabList onChange={handleTabChange} textColor="secondary" indicatorColor="secondary" className="tabContainer">
                        <Tab label="Mes dossiers" value="personalItems" className="tab" />
                        <Tab label="Dossiers du service" value="serviceItems" className="tab" />
                    </TabList>
                    <TabPanel value="personalItems" className='synoptique__content-noPadding' sx={{ padding: 0 }}>
                        <div className='synoptique__content'>
                            <div className='synoptique__section'>
                                <span className='title'>EmerGa Urgence</span>
                                <SynoptiqueDossiers isCreatingAffairs={isCreatingAffairs} selectedDossiers={selectedDossiers} setSelectedDossiers={setSelectedDossiers} setOpened={setOpened} dossiers={folders.userUrgences} />
                            </div>

                            <div className='synoptique__section'>
                                <span className='title'>Appels manuels</span>
                                <SynoptiqueDossiers isCreatingAffairs={isCreatingAffairs} selectedDossiers={selectedDossiers} setSelectedDossiers={setSelectedDossiers} setOpened={setOpened} dossiers={folders.userManualTreatments} />
                            </div>

                            <div className='synoptique__section'>
                                <span className='title'>Affaires</span>
                                <SynoptiqueDossiers isCreatingAffairs={false} selectedDossiers={selectedDossiers} setSelectedDossiers={setSelectedDossiers} setOpened={setOpened} dossiers={folders.userAffairs} affair={true} />
                            </div>

                            <div className='synoptique__section'>
                                <span className='title'>Terminé / transmis</span>
                                <SynoptiqueDossiers isCreatingAffairs={isCreatingAffairs} selectedDossiers={selectedDossiers} setSelectedDossiers={setSelectedDossiers} setOpened={setOpened} dossiers={folders.pastUserFolders} closed={true} />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="serviceItems" className='synoptique__content-noPadding' sx={{ padding: 0 }}>
                        <div className='synoptique__content'>
                            <div className='synoptique__section'>
                                <span className='title'>EmerGa Urgence</span>
                                <SynoptiqueDossiers
                                    isCreatingAffairs={isCreatingAffairs}
                                    selectedDossiers={selectedDossiers}
                                    setSelectedDossiers={setSelectedDossiers}
                                    setOpened={setOpened}
                                    dossiers={folders.serviceUrgences}
                                />
                            </div>

                            <div className='synoptique__section'>
                                <span className='title'>Appels manuels</span>
                                <SynoptiqueDossiers
                                    isCreatingAffairs={isCreatingAffairs}
                                    selectedDossiers={selectedDossiers}
                                    setSelectedDossiers={setSelectedDossiers}
                                    setOpened={setOpened}
                                    dossiers={folders.serviceManualTreatments} />
                            </div>

                            <div className='synoptique__section'>
                                <span className='title'>Affaires</span>
                                <SynoptiqueDossiers
                                    isCreatingAffairs={false}
                                    selectedDossiers={selectedDossiers}
                                    setSelectedDossiers={setSelectedDossiers}
                                    setOpened={setOpened}
                                    dossiers={folders.serviceAffairs}
                                    affair={true} />
                            </div>

                            <div className='synoptique__section'>
                                <span className='title'>Terminé / transmis</span>
                                <SynoptiqueDossiers
                                    isCreatingAffairs={isCreatingAffairs}
                                    selectedDossiers={selectedDossiers}
                                    setSelectedDossiers={setSelectedDossiers}
                                    setOpened={setOpened}
                                    dossiers={folders.pastServiceFolders}
                                    closed={true} />

                            </div>
                        </div>
                    </TabPanel>
                </TabContext>

                <div style={{
                    display: 'flex',
                    gap: "10px",
                    padding: "10px",
                    width: "calc(100% - 20px)",
                    flexWrap: "wrap"
                }}>
                    <Link to="/appel" className="outlinedButton" style={{ width: "277px" }}><CallIcon sx={{ fontSize: 20 }} />Ajouter un appel</Link>

                    <OutlinedButton style={{ width: "277px" }} filled={isCreatingAffairs ? true : false}
                        onClick={() => {
                            if (!isCreatingAffairs) {
                                setCreatingAffairs(true)
                            } else {
                                props.socket.emit("SINAPSE_CREATE_AFFAIR", {
                                    jwt: props.token,
                                    items: selectedDossiers
                                }, (response) => {
                                    if (response.status) {
                                        if (response.status === 'DONE') {
                                            dispatch(setAlert({ type: "success", message: "Enregistré" }))
                                        }
                                    }
                                })
                            }
                        }}
                    ><MedicalServicesIcon sx={{ fontSize: 20 }} /> Créer une affaire</OutlinedButton>
                </div>

            </div>
        )

    }
}

export default Synoptique

function isItemInSearchQuery(result, search) {

    if (!result.city) { result.city = ""; }
    if (!result.street) { result.street = ""; }
    return result.city.match(new RegExp(`${search}`, 'gi')) || result.street.match(new RegExp(`${search}`, 'gi')) || result.diagnosis_details?.situation?.name.match(new RegExp(`${search}`, 'gi'));
}
