import logo from "../assets/SINAPSE-white.png"
import OutlinedButton from "./OutlinedButton"

import ModeNightIcon from '@mui/icons-material/ModeNight';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import { useSelector } from "react-redux";

const Navbar = (props) => {

    const userInformations = useSelector(Store => Store.SINAPSE.userInformations)

    return (
        <>
            <nav className="navbar">
                <div className="side">
                    <a>
                        <img src={logo} alt="SINAPSE™" className="navbar__logo" />
                    </a>
                    <OutlinedButton white={true}><ModeNightIcon sx={{ fontSize: 20 }} />Mode sombre</OutlinedButton>
                    <OutlinedButton white={true}>
                        <SettingsIcon sx={{ fontSize: 20 }} />
                        Configuration
                        <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
                    </OutlinedButton>
                </div>
                <div className="side">
                    <OutlinedButton white={true}>
                        <RadioButtonCheckedIcon sx={{ fontSize: 20, color: '#A8D6B4' }} />
                        Actif
                        <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
                    </OutlinedButton>
                    <OutlinedButton white={true}>
                        <PersonOutlineOutlinedIcon sx={{ fontSize: 20 }} />
                        {userInformations.firstName} {userInformations.lastName} - {userInformations.type}
                        <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
                    </OutlinedButton>
                </div>

            </nav>
            <div className="navbar__mask"></div>
        </>

    )
}

export default Navbar