import { configureStore } from '@reduxjs/toolkit'
import SINAPSEReducer from './reducer'

export {
    setRunning,
    loadUserInformations,
    updateSocketStatus,
    setLastSocketPong,
    setDossiers,
    setParsedDossiers,
    setError,
    setCurrentFolder,
    setAlreadyAskedForFolders,
    setSituations,
    setAlert,
    setDisplayedFolders,
    setCurrentAffair,
} from "./reducer"

const Store = configureStore({
    reducer: {
        SINAPSE: SINAPSEReducer,
    },
})

export default Store;