const ValuesDictionary = (values) => {
    var items = [],
        returnItems = {
            description: {
                information: [],
                warning: [],
                alert: []
            },
            victim: {
                information: [],
                warning: [],
                alert: []
            },
        }
console.log(Object.entries(values))
    Object.entries(values).forEach(([variable, value]) => {
        switch (variable) {
            case 'TEST': items.push({
                type: 'description',
                message: "DÉCLENCHEMENT TEST",
                kind: 'alert'
            })
                break;
            case 'SITUATION_VICTIME':
                if (!value) {
                    items.push({
                        type: 'victim',
                        message: 'AUCUNE VICTIME',
                        kind: 'warning'
                    })
                }
                break;
            case 'SITUATION_SAUVETEUR_EST_VICTIME':
                if (value) {
                    items.push({
                        type: 'victim',
                        message: 'LA VICTIME EST LE REQUÉRANT',
                        kind: 'warning'
                    })
                }
                break;
            case 'SITUATION_SAUVETEUR_AVEC_VICTIME':
                if (value) {
                    items.push({
                        type: 'victim',
                        message: "La victime est avec le requérant.",
                        kind: 'information'
                    })
                } else {
                    items.push({
                        type: 'victim',
                        message: "La victime et le requérant ne sont pas ensemble.",
                        kind: 'warning'
                    })
                }
                break;


            case 'VICTIME_BRULURE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: 'La victime présente une brûlure.',
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_BRULURE_ASPECT_SDG':
                if (value) {
                    items.push({
                        type: 'description',
                        message: 'La brûlure de la victime présente un aspect cloqué, blanchâtre ou noirâtre (signe de gravité)',
                        kind: 'warning'
                    })
                } else {
                    items.push({
                        type: 'description',
                        message: 'La brûlure de la victime ne présente pas d\'aspect cloqué, blanchâtre ou noirâtre.',
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_BRULURE_LOCALISATION_SDG':
                if (value) {
                    items.push({
                        type: 'description',
                        message: 'La localisation de la brûlure est à risque (signe de gravité)',
                        kind: 'warning'
                    })
                } else {
                    items.push({
                        type: 'description',
                        message: "La localisation de la brûlure n'est pas à risque.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_BRULURE_TAILLE_SDG':
                if (value) {
                    items.push({
                        type: 'description',
                        message: 'La taille de la brûlure est à risque (signe de gravité)',
                        kind: 'warning'
                    })
                } else {
                    items.push({
                        type: 'description',
                        message: "La taille de la brûlure n'est pas à risque.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_CHOC_ELECTRIQUE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime a été exposée à un choc électrique",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_CHUTE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime a chuté.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_CONFUSE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime est désorientée, agitée ou confuse.",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_CONSCIENTE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "Victime consciente.",
                        kind: 'information'
                    })
                } else {
                    items.push({
                        type: 'description',
                        message: "Victime inconsciente",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_CONVULSIONS_RAIDEUR':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime convulse ou présente des raideurs",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_DECLA_CEPHALEES':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime se plaint de céphalées.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_DECLA_EXPOSITION_CHALEUR':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime a été exposée à de fortes chaleurs.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_DECLA_FAIBLESSE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime décrit une sensation de faiblesse généralisée.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_DECLA_MALAISE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime est en état de malaise",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_DECLA_OPRESSION_THO':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime décrit une sensation d'oppression thoracique",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_DYSPNEE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime se plaint de dyspnée",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_DECLA_NAUSEES':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime se plaint de nausées.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_GASP':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime présente des gasp",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_HEMORRAGIE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime présente une hémorragie",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_HEMORRAGIE_ORIFICE_NATUREL':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "Hémorragie extériorisée.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_MEMBRE_SECTIONNE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "Membre sectionné",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_OVA_SIGNES_TOTAL':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "Obstruction totale des voies aériennes",
                        kind: 'alert'
                    })
                }
                break;
            case 'VICTIME_PARALYSIE_ASYMETRIQUE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime présente une paralysie asymétrique.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_PARALYSIE_FACIALE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime présente une paralysie faciale.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_PAROLE_TROUBLEE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime présente un trouble de la parole.",
                        kind: 'information'
                    })
                }
                break;
            case 'VICTIME_PCI':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime a perdu connaissance (PCI)",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_PLAIE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime présente une plaie",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_PLAIE_ASPECT_SDG':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La plaie présente un aspect écrasé ou déchiqueté (signe de gravité)",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_PLAIE_LOCALISATION_SDG':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La localisation de la plaie est à risque (signe de gravité)",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_PLAIE_HEMORRAGIE_SDG':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La plaie saigne abondamment (signe de gravité)",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_PLAIE_CORPS_ETRANGER':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La plaie ne comporte pas de corps étranger.",
                        kind: 'information'
                    })
                } else {
                    items.push({
                        type: 'description',
                        message: "La plaie comporte un corps étranger.",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_RESPIRE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "Respiration perçue.",
                        kind: 'information'
                    })
                } else {
                    items.push({
                        type: 'description',
                        message: "Défaut de respiration",
                        kind: 'alert'
                    })
                }
                break;
            case 'VICTIME_SIGNE_AVC':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "Signe de suspicion d'AVC",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_TC':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime a fait un traumatisme crânien",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_TETANISEE':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime est tétanisée",
                        kind: 'warning'
                    })
                }
                break;
            case 'VICTIME_TRAUMA':
                if (value) {
                    items.push({
                        type: 'description',
                        message: "La victime est traumatisée.",
                        kind: 'information'
                    })
                }
                break;



            case 'pls':
                if (value === true) {
                    items.push({
                        type: 'description',
                        message: 'Victime placée en PLS.',
                        kind: 'information'
                    })
                }
                if (value === "PENDING") {
                    items.push({
                        type: 'description',
                        message: 'Le requérant est en train de placer la victime en PLS.',
                        kind: 'information'
                    })
                }
                break;
            case 'compressive_plaster':
                if (value === true) {
                    items.push({
                        type: 'description',
                        message: "Le requérant a mis en place un pansement compressif ou comprime l'hémorragie.",
                        kind: 'information'
                    })
                }
                if (value === "PENDING") {
                    items.push({
                        type: 'description',
                        message: "Le requérant est en train de mettre en place un pansement compressif ou de comprimer l'hémorragie.",
                        kind: 'information'
                    })
                }
                break;

            case 'garrot':
                if (value === true) {
                    items.push({
                        type: 'description',
                        message: "LE REQUÉRANT A MIS EN PLACE UN GARROT.",
                        kind: 'warning'
                    })
                }
                if (value === "PENDING") {
                    items.push({
                        type: 'description',
                        message: "LE REQUÉRANT EST EN TRAIN DE METTRE EN PLACE UN GARROT.",
                        kind: 'warning'
                    })
                }
                break;
            default:
                break;
        }
    })

    items.forEach((item, index) => {
        if (item.type === 'description') {
            if (item.kind === "information") {
                returnItems.description.information.push(item)
            }
            if (item.kind === "warning") {
                returnItems.description.warning.push(item)
            }
            if (item.kind === "alert") {
                returnItems.description.alert.push(item)
            }
        }
        if (item.type === 'victim') {
            if (item.kind === "information") {
                returnItems.victim.information.push(item)
            }
            if (item.kind === "warning") {
                returnItems.victim.warning.push(item)
            }
            if (item.kind === "alert") {
                returnItems.victim.alert.push(item)
            }
        }
    })

    return returnItems
}

export { ValuesDictionary }