import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MapIcon from '@mui/icons-material/Map';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import HealingIcon from '@mui/icons-material/Healing';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CallIcon from '@mui/icons-material/Call';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SaveIcon from '@mui/icons-material/Save';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedButton from "../components/OutlinedButton"

import { AsYouType } from 'libphonenumber-js';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import { getGravity, ValuesDictionary } from '../utils';
import { setCurrentFolder, setAlert } from "../store"

import moment from 'moment';

const sortProposedSituations = async (search, situations, setActiveSituations, setChosenGesture, setGravity, setNDF, openGestureModal) => {
    let activeSituations = [];

    if (search === "") {
        activeSituations = situations;
    } else {
        activeSituations = [];

        console.log(situations)
        activeSituations = situations.filter(result => {
            let gn = result.gesture_name ?? ""
            let gd = result.gesture_description ?? ""

            return result.name.match(new RegExp(`${search}`, 'gi')) || gn.match(new RegExp(`${search}`, 'gi')) || gd.match(new RegExp(`${search}`, 'gi'))
        });
    }

    let as = []

    activeSituations.forEach(item => {
        as.push(
            <tr key={item.id}>
                <td className='situationColumn'>
                    <div className={'gravityTag ' + item.gravity}></div>
                    <b>{item.name}</b>
                </td>
                <td className='gestureColumn'>
                    <p>{item.gesture_name}</p>
                    <small>{item.gesture_description}</small>
                </td>
                <td className='actionColumn'>
                    <OutlinedButton filled disabled={item.gesture_name ? false : true} onClick={() => {
                        setChosenGesture(item.id)
                        setGravity(item.gravity)
                        setNDF(item.name)
                        openGestureModal(false)
                    }}>Sélectionner</OutlinedButton>
                </td>
            </tr>
        )
    })

    console.log(as)
    setActiveSituations(as)
}

const CreateManualTreatment = (props) => {

    const [gestureModalOpened, openGestureModal] = useState(false)
    const [activeSituations, setActiveSituations] = useState([])

    const [consigneTerms, setConsigneTerms] = useState("")
    const [observationTerms, setObservationTerms] = useState("")
    const [searchTermsGestures, setSearchTermsGestures] = useState("")
    const [chosenGesture, setChosenGesture] = useState(null)
    const [rescuerPhone, setRescuerPhone] = useState("")
    const [gravity, setGravity] = useState("")
    const [ndf, setNDF] = useState("")
    const [address, setAddress] = useState("")

    const socket = props.socket;

    moment.locale();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const situations = useSelector(Store => Store.SINAPSE.situations)
    const token = useSelector((Store) => Store.SINAPSE.token);
    const instanceInformations = useSelector((Store) => Store.SINAPSE.instanceInformations);

    return (
        <>
            <div className="informations manualTreatment">

                <div className='manualTreatment__actions'>
                    <OutlinedButton onClick={() => { navigate("/") }}><CloseIcon sx={{ fontSize: 20 }} /> Annuler</OutlinedButton>

                    <OutlinedButton filled
                        onClick={() => {
                            socket.emit("SINAPSE_CREATE_MANUAL_TREATMENT", {
                                jwt: token,
                                rescuerPhone: rescuerPhone,
                                gravity: gravity,
                                situationId: chosenGesture,
                                sinapseDemands: {}
                            }, (response) => {
                                if (response.status) {
                                    if (response.status === 'DONE') {
                                        dispatch(setAlert({ type: "success", message: "Enregistré" }))
                                        navigate("/")
                                    }
                                }
                            })
                        }}
                    ><SaveIcon sx={{ fontSize: 20 }} /> Enregistrer</OutlinedButton>
                </div>


                <h1>Prise d'appel</h1>


                <div className="informations__body">
                    <div className="informations__body-incident">
                        <div className="informations__body-incidentContainer">
                            <span className="title">Incident</span>

                            <div className="incidentHeader">
                                <b className="incidentTitle">{
                                    ndf !== "" ? ndf : "Nature de fait inconnue"
                                }</b>
                                <FormControl className="informations__incident-gravity">
                                    <Select
                                        displayEmpty
                                        className="informations__incident-gravity-select"
                                        value={gravity}
                                        onChange={(event) => {
                                            setGravity(event.target.value)
                                        }}
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            '&:has(input[value="eu"])': {
                                                backgroundColor: 'alert.main',
                                                color: 'white.main',
                                            },
                                            '&:has(input[value="u1"])': {
                                                backgroundColor: 'warning.main',
                                                color: 'secondary.main',
                                            },
                                            '&:has(input[value="u2"])': {
                                                backgroundColor: 'warning.light',
                                                color: 'secondary.main',
                                            },
                                            '&:has(input[value="u3"])': {
                                                backgroundColor: 'green.light',
                                                color: 'secondary.main',
                                            },
                                            '& .MuiSelect-select': {
                                                fontSize: 14,
                                                padding: '10px 40px 10px 15px'
                                            },
                                            '& svg': {
                                                width: 18
                                            }
                                        }}
                                    >
                                        <MenuItem value={'eu'} sx={{
                                            fontSize: 14,
                                            backgroundColor: 'alert.main',
                                            color: 'white.main',
                                            fontWeight: 500,
                                            '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                                backgroundColor: 'alert.main',
                                            },
                                            'ul:has(> &)': {
                                                padding: 0
                                            }
                                        }} className="informations__incident-gravity-select-eu">EU</MenuItem>
                                        <MenuItem value={'u1'} sx={{
                                            fontSize: 14,
                                            backgroundColor: 'warning.main',
                                            color: 'secondary.main',
                                            fontWeight: 500,
                                            '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                                backgroundColor: 'warning.main',
                                            }
                                        }} className="informations__incident-gravity-select-u1">U1</MenuItem>
                                        <MenuItem value={'u2'} sx={{
                                            fontSize: 14,
                                            backgroundColor: 'warning.light',
                                            color: 'secondary.main',
                                            fontWeight: 500,
                                            '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                                backgroundColor: 'warning.light',
                                            }
                                        }} className="informations__incident-gravity-select-u2">U2</MenuItem>
                                        <MenuItem value={'u3'} sx={{
                                            fontSize: 14,
                                            backgroundColor: 'green.light',
                                            color: 'secondary.main',
                                            fontWeight: 500,
                                            '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                                backgroundColor: 'green.light',
                                            }
                                        }} className="informations__incident-gravity-select-u3">U3</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="incidentLocationData">
                                <div>
                                    <div className="header">
                                        <MapIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                        <span>Adresse</span>
                                    </div>
                                    <OutlinedInput
                                        placeholder='Adresse'
                                        type='text'
                                        className='search'
                                        value={address}
                                        onChange={
                                            (e) => { setAddress(e.target.value) }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="incidentMap">
                                <MapContainer center={["43.345917", "1.181540"]} zoom={8} scrollWheelZoom={true} className="incidentMap-map">
                                    <TileLayer
                                        attribution='&copy; Données <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> - SINAPSE™'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </MapContainer>
                            </div>
                        </div>
                    </div>

                    <div className="informations__body-requerant">
                        <span className="title">Requérant</span>

                        <p>Numéro de téléphone :</p>
                        <OutlinedInput
                            placeholder='Numéro de téléphone'
                            type='text'
                            className='search'
                            value={new AsYouType('FR').input(rescuerPhone)}
                            onChange={
                                (e) => { setRescuerPhone(e.target.value) }
                            }
                        />
                    </div>

                    <div className="informations__body-emerga">
                        <span className="title">EmerGa</span>
                        <div className='informations__section-slider'>
                            <p>Récupérez des informations et échangez avec le requérant.</p>

                            <OutlinedButton style={{ marginBottom: "25px", marginTop: "15px" }} filled fullwidth onClick={() => { openGestureModal(true) }}><HealingIcon sx={{ fontSize: 20 }} />Afficher un geste d'urgence</OutlinedButton>

                            <b>Consigne personnalisée</b>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "5px", width: "100%", marginTop: "5px", alignItems: "flex-start" }}>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Saisissez une consigne"
                                    className='search'
                                    multiline
                                    value={consigneTerms}
                                    onChange={(t) => {
                                        setConsigneTerms(t.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="informations__body-actions">
                        <OutlinedButton fullwidth whiteFilled leftIcon>
                            <PhotoCameraIcon sx={{ fontSize: 20 }} />Demander une photographie
                        </OutlinedButton>
                        <OutlinedButton href={"tel:" + rescuerPhone} fullwidth whiteFilled leftIcon>
                            <CallIcon sx={{ fontSize: 20 }} />Lancer un appel téléphonique
                        </OutlinedButton>
                        <OutlinedButton fullwidth whiteFilled leftIcon>
                            <GpsFixedIcon sx={{ fontSize: 20 }} />Demander une géolocalisation
                        </OutlinedButton>
                    </div>

                    <div className="informations__body-dossier">
                        <div className='observations'>
                            <span className="title">Observations</span>
                            <div style={{
                                display: "grid",
                                gap: "10px",
                                gridTemplateColumns: "1fr 50px",
                                width: "100%"
                            }}>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Saisissez une observation"
                                    className='search'
                                    multiline
                                    sx={{
                                        height: "75px", textAlign: "flex-start",
                                        inputMultiline: {
                                            height: "45px"
                                        }
                                    }}
                                    value={observationTerms}
                                    onChange={(t) => {
                                        setObservationTerms(t.target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <div className='operations'>
                            <div className='container'>
                                <p>Associer à une affaire :</p>
                                <FormControl sx={{
                                    margin: "3px 0 0 0", minWidth: 120
                                }} size="small">
                                    <Select
                                        value={"NOAFFAIR"}
                                        sx={{
                                            select: {
                                                fontSize: "15px", backgroundColor: "#ffffff"
                                            }, backgroundColor: "#ffffff", fontSize: "15px"
                                        }}
                                    >
                                        <MenuItem disabled value="NOAFFAIR">
                                            <em>Aucune affaire sélectionnée</em>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                            <div className='container'>
                                <p>Utilisateurs :</p>
                                <FormControl sx={{ margin: "3px 0 0 0", minWidth: 120, backgroundColor: "#ffffff" }} size="small">
                                    <Select
                                        value='ARM'
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                <Chip key={"ARM"} label={"ARM"} />
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value="ARM" selected>
                                            <Checkbox checked={true} />
                                            <ListItemText primary="ARM" />
                                        </MenuItem>
                                        <MenuItem value="MR">
                                            <Checkbox />
                                            <ListItemText primary="MR" />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div className='container'>
                                <p>Services :</p>
                                <FormControl sx={{ margin: "3px 0 0 0", minWidth: 120, backgroundColor: "#ffffff" }} size="small">
                                    <Select
                                        value='SAMU31'
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                <Chip key={"CRRA 31"} label={"CRRA 31"} />
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value="SAMU31" selected>
                                            <Checkbox checked={true} />
                                            <ListItemText primary="CRRA 31" />
                                        </MenuItem>
                                        <MenuItem value="SDIS31" selected>
                                            <Checkbox checked={false} />
                                            <ListItemText primary="CTA-CODIS 31" />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Backdrop open={gestureModalOpened} sx={{ zIndex: 500 }}>
                <div className='modal'>
                    <CloseIcon className="close" onClick={() => { openGestureModal(false) }} />
                    <h3>Afficher un geste d'urgence</h3>
                    <p>Sélectionnez un geste d'urgence à afficher sur l'écran du requérant pour l'aider à mettre en œuvre les mesures conservatoires appropriées.</p>

                    <OutlinedInput
                        type="text"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        sx={{ margin: "25px 0 25px 0" }}
                        placeholder="Incident, geste d'urgence..."
                        className='search'
                        value={searchTermsGestures}
                        onChange={(t) => {
                            setSearchTermsGestures(t.target.value)
                            sortProposedSituations(t.target.value, situations, setActiveSituations, setChosenGesture, setGravity, setNDF, openGestureModal)
                        }}
                    />

                    <div className='situationsTable-container'>
                        <table className='situationsTable'>
                            <colgroup>
                                <col span="1" style={{ width: '40%' }} />
                                <col span="1" style={{ width: '40%' }} />
                                <col span="1" style={{ width: '20%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className='situationColumn'>Situation</th>
                                    <th className='gestureColumn'>Mesure conservatoire</th>
                                    <th className='actionColumn'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    activeSituations
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Backdrop>

        </>
    )
}

export { CreateManualTreatment };