import { createSlice } from '@reduxjs/toolkit'

export const SINAPSEReducer = createSlice({
    name: 'SINAPSEReducer',
    initialState: {
        isRunning: false,
        isLoggedIn: false,
        userInformations: {},
        instanceInformations: {},
        token: null,
        socketStatus: false,
        socketId: null,
        lastSocketPong: null,
        dossiers: {},
        parsedDossiers: {
            serviceUrgences: {},
            userUrgences: {},
            serviceManualTreatments: {},
            userManualTreatments: {},
            serviceAffairs: {},
            userAffairs: {},
            alerts: {},
            pastUserFolders: {},
            pastServiceFolders: {}
        },
        areFoldersParsed: false,
        error: {
            message: "Une erreur est survenue.",
            active: false
        },
        currentFolder: {},
        currentAffair: {},
        alreadyAskedForFolders: false,
        alerts: {},
        situations: [],
        displayedFolders: []
    },
    reducers: {
        setRunning: (state) => {
            state.isRunning = true
        },
        loadUserInformations: (state, action) => {
            state.isLoggedIn = true;
            state.userInformations = action.payload.userInformations;
            state.instanceInformations = action.payload.instanceInformations;
            state.token = action.payload.token;
        },
        updateSocketStatus: (state, action) => {
            state.socketStatus = action.payload.socketRunning ?? state.socketStatus
            state.socketId = action.payload.socketId ?? state.socketId
        },
        setLastSocketPong: (state, action) => {
            state.lastSocketPong = action.payload;
        },
        setDossiers: (state, action) => {
            state.dossiers = action.payload
        },
        setParsedDossiers: (state, action) => {
            state.parsedDossiers = action.payload
            state.areFoldersParsed = true
        },
        setError: (state, action) => {
            state.error = {
                message: action.payload.message ?? "Une erreur est survenue.",
                type:action.payload.type ?? "alert",
                active: action.payload.active ?? false
            }
        },
        setCurrentFolder: (state, action) => {
            if (typeof action.payload === 'object') {
                state.currentFolder = action.payload
            } else {
                state.currentFolder = {}
            }
        },
        setCurrentAffair: (state, action) => {
            if (typeof action.payload === 'object') {
                state.currentAffair = action.payload
            } else {
                state.currentAffair = {}
            }
        },
        setAlreadyAskedForFolders: (state, action) => {
            state.alreadyAskedForFolders = action.payload
        },
        setSituations: (state, action) => {
            state.situations = action.payload
        },
        setAlert: (state, action) => {
            state.alerts = action.payload
        },
        setDisplayedFolders: (state, action) => {
            state.displayedFolders = action.payload;
        }
    },
})

export const {
    setRunning,
    loadUserInformations,
    updateSocketStatus,
    setLastSocketPong,
    setDossiers,
    setParsedDossiers,
    setError,
    setCurrentFolder,
    setAlreadyAskedForFolders,
    setSituations,
    setAlert,
    setDisplayedFolders,
    setCurrentAffair,
} = SINAPSEReducer.actions

export default SINAPSEReducer.reducer