import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MapIcon from '@mui/icons-material/Map';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import HealingIcon from '@mui/icons-material/Healing';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CallIcon from '@mui/icons-material/Call';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import SupportIcon from '@mui/icons-material/Support';
import PersonIcon from '@mui/icons-material/Person';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedButton from "../components/OutlinedButton"

import { AsYouType } from 'libphonenumber-js';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import { getGravity, ValuesDictionary } from '../utils';
import { setCurrentFolder, setAlert } from "../store"

import moment from 'moment';
import humanizeDuration from "humanize-duration";

const sortProposedSituations = async (search, situations, setActiveSituations) => {
    let activeSituations = [];

    if (search === "") {
        activeSituations = situations;
    } else {
        activeSituations = [];

        activeSituations = situations.filter(result => {
            let gn = result.gesture_name ?? ""
            let gd = result.gesture_description ?? ""

            return result.name.match(new RegExp(`${search}`, 'gi')) || gn.match(new RegExp(`${search}`, 'gi')) || gd.match(new RegExp(`${search}`, 'gi'))
        });
    }

    let as = []

    activeSituations.forEach(item => {
        as.push(
            <tr key={item.id}>
                <td className='situationColumn'>
                    <div className={'gravityTag ' + item.gravity}></div>
                    <b>{item.name}</b>
                </td>
                <td className='gestureColumn'>
                    <p>{item.gesture_name}</p>
                    <small>{item.gesture_description}</small>
                </td>
                <td className='actionColumn'>
                    <OutlinedButton filled disabled={item.gesture_name ? false : true}>Envoyer</OutlinedButton>
                </td>
            </tr>
        )
    })

    setActiveSituations(as)
}

const Informations = (props) => {

    const [gestureModalOpened, openGestureModal] = useState(false)
    const [videoCallModal, openVideoCallModal] = useState(false)
    const [searchTermsGestures, setSearchTermsGestures] = useState("")
    const [consigneTerms, setConsigneTerms] = useState("")
    const [observationTerms, setObservationTerms] = useState("")
    const [activeSituations, setActiveSituations] = useState([])


    const socket = props.socket;

    moment.locale();

    const dispatch = useDispatch();

    const situations = useSelector(Store => Store.SINAPSE.situations)
    const currentFolder = useSelector(Store => Store.SINAPSE.currentFolder);
    const parsedDossiers = useSelector((Store) => Store.SINAPSE.parsedDossiers);
    const token = useSelector((Store) => Store.SINAPSE.token);
    const instanceInformations = useSelector((Store) => Store.SINAPSE.instanceInformations);

    var dossierData = {}

    if (Object.entries(parsedDossiers).length && currentFolder.type) {
        if (currentFolder.closed) {
            if (currentFolder.type === 1) {
                if (Object.entries(parsedDossiers.pastServiceFolders).length) {
                    dossierData = { ...parsedDossiers.pastServiceFolders[currentFolder.id] }
                }
            } else if (currentFolder.type === 2) {

                if (Object.entries(parsedDossiers.pastServiceFolders).length) {
                    dossierData = { ...parsedDossiers.pastServiceFolders[currentFolder.id] }
                }
            }

        } else if (currentFolder.type === 1) {
            if (Object.entries(parsedDossiers["serviceUrgences"]).length) {
                dossierData = { ...parsedDossiers["serviceUrgences"][currentFolder.id] }
            }
        } else if (currentFolder.type === 2) {
            if (Object.entries(parsedDossiers["serviceManualTreatments"]).length) {
                dossierData = { ...parsedDossiers["serviceManualTreatments"][currentFolder.id] }
            }
        }
    }

    console.log(dossierData)

    if (Object.entries(dossierData).length) {

        Object.entries(dossierData.sinapseUsers).forEach(([index, user]) => {
            if (user.initial_user) {
                dossierData.initialSinapseUser = {
                    ...user
                }
            }

        })

        if (typeof dossierData.support_details == "string") {
            dossierData.support_details.replace("\\", "");
            try {
                dossierData.support_details = JSON.parse(dossierData.support_details)
            } catch(e) {
                console.log(e)
            }
        }

        var diagnosisValues = {
            description: {
                information: [],
                warning: [],
                alert: [],
            }, victim: {
                information: [],
                warning: [],
                alert: [],
            },
        },
            valuesDescriptionInfo = [],
            valuesDescriptionWarnings = [],
            valuesDescriptionAlerts = [],
            valuesVictimInfo = [],
            valuesVictimWarnings = [],
            valuesVictimAlerts = [],
            rescueValues = {
                description: {
                    information: [],
                    warning: [],
                    alert: [],
                }
            },
            valuesRescueDescriptionInfo = [],
            valuesRescueDescriptionWarning = [],
            valuesRescueDescriptionAlerts = [],
            csDescriptionInfo = [],
            csDescriptionWarning = [],
            csDescriptionAlerts = [],
            csVictimInfo = [],
            csVictimWarning = [],
            csVictimAlerts = [];

        if (dossierData.rescue_details.values) {
            rescueValues = ValuesDictionary(dossierData.rescue_details.values)
        }
        if (dossierData.diagnosis_details.variables) {
            diagnosisValues = ValuesDictionary(dossierData.diagnosis_details.variables)
        } else if (dossierData.diagnosis_details.values) {
            diagnosisValues = ValuesDictionary(dossierData.diagnosis_details.values)
        }

        var clinicalSigns = {
            description: {
                information: [],
                warning: [],
                alert: []
            },
            victim: {
                information: [],
                warning: [],
                alert: []
            }
        };

        if (dossierData.diagnosis_details.clinicalSigns) {
            clinicalSigns = ValuesDictionary(dossierData.diagnosis_details.clinicalSigns)
        }

        diagnosisValues.description.information.forEach((item, i) => {
            valuesDescriptionInfo.push(<p key={i}>{item.message}</p>)
        })
        diagnosisValues.victim.information.forEach((item, i) => {
            valuesVictimInfo.push(<p key={i}>{item.message}</p>)
        })

        diagnosisValues.description.warning.forEach((item, i) => {
            valuesDescriptionWarnings.push(<Alert color="warning" severity="warning" key={i}>{item.message.toUpperCase()}</Alert>)
        })
        diagnosisValues.victim.warning.forEach((item, i) => {
            valuesVictimWarnings.push(<Alert severity="warning" key={i}>{item.message.toUpperCase()}</Alert>)
        })

        diagnosisValues.description.alert.forEach((item, i) => {
            valuesDescriptionAlerts.push(<Alert severity="error" key={i}>{item.message.toUpperCase()}</Alert>)
        })
        diagnosisValues.victim.alert.forEach((item, i) => {
            valuesVictimAlerts.push(<Alert severity="error" key={i}>{item.message.toUpperCase()}</Alert>)
        })

        clinicalSigns.description.information.forEach((item, i) => {
            csDescriptionInfo.push(<p key={i}>{item.message}</p>)
        })
        clinicalSigns.description.warning.forEach((item, i) => {
            csDescriptionWarning.push(<Alert severity="warning" key={i}>{item.message.toUpperCase()}</Alert>)
        })
        clinicalSigns.description.alert.forEach((item, i) => {
            csDescriptionAlerts.push(<Alert severity="error" key={i}>{item.message.toUpperCase()}</Alert>)
        })
        clinicalSigns.victim.information.forEach((item, i) => {
            csVictimInfo.push(<p key={i}>{item.message}</p>)
        })
        clinicalSigns.victim.warning.forEach((item, i) => {
            csVictimWarning.push(<Alert severity="warning" key={i}>{item.message.toUpperCase()}</Alert>)
        })
        clinicalSigns.victim.alert.forEach((item, i) => {
            csVictimAlerts.push(<Alert severity="error" key={i}>{item.message.toUpperCase()}</Alert>)
        })

        rescueValues.description.information.forEach((item, i) => {
            valuesRescueDescriptionInfo.push(<p key={i}>{item.message}</p>)
        })
        rescueValues.description.warning.forEach((item, i) => {
            valuesRescueDescriptionWarning.push(<Alert color="warning" severity="warning" key={i}>{item.message.toUpperCase()}</Alert>)
        })
        rescueValues.description.alert.forEach((item, i) => {
            valuesRescueDescriptionAlerts.push(<Alert severity="error" key={i}>{item.message.toUpperCase()}</Alert>)
        })

        var isRequerant = Object.entries(dossierData.incident_details.userDetails).length;

        var observationsContinues = [];

        if (dossierData.support_details.observations) {
            dossierData.support_details.observations.forEach((obs, index) => {
                observationsContinues.push(
                    <div className={"observationItem"} key={index}>
                        <small className={obs.author.publicName === instanceInformations.public_name ? " sameService" : ""}>
                            <b>{obs.author.firstName + " " + obs.author.lastName}</b><br />
                            ({obs.author.type + "/" + obs.author.publicName})
                        </small>
                        <p>
                            {obs.content}
                        </p>
                    </div>
                )
            })
        }

        if (!dossierData.incident_type) {
            dossierData.incident_type = null
        }
        if (!dossierData.incident_details.address) {
            dossierData.incident_details = {
                ...dossierData.incident_details,
                address: {
                    type: null
                }
            }
        }

        var victimAge = null,
            antecedentsFamiliaux = [],
            traitements = [],
            pathologies = [],
            antecedents = [];

        if (dossierData.incident_details.victimDetails) {
            let birthDate = new Date(dossierData.incident_details.victimDetails.birthDate);
            let currentDate = new Date();
            victimAge = humanizeDuration(currentDate - birthDate, {
                language: "fr",
                units: ["y", "mo", "d"],
                round: true,
                largest: 1
            })

            dossierData.incident_details.victimDetails.antecedents.forEach((item, index) => {
                antecedents.push(
                    <div className='item' key={index}>
                        <Chip label={item.type} className="chip" sx={{ fontSize: "13px" }} />
                        <p>{item.name} - {moment(item.date).format("DD/MM/YYYY")}</p>
                        <small>{item.description}</small>
                    </div>
                )
            })

            dossierData.incident_details.victimDetails.antecedentsFamiliaux.forEach((item, index) => {
                antecedentsFamiliaux.push(
                    <div className='item' key={index}>
                        <Chip label={item.parente} className="chip" sx={{ fontSize: "13px" }} />
                        <p>{item.name}</p>
                        <small>{item.description}</small>
                    </div>
                )
            })

            dossierData.incident_details.victimDetails.pathologies.forEach((item, index) => {
                pathologies.push(
                    <div className='item' key={index}>
                        <p>{item.name}</p>
                        <small>{item.description}</small>
                    </div>
                )
            })

            dossierData.incident_details.victimDetails.treatments.forEach((item, index) => {
                traitements.push(
                    <div className='item' key={index}>
                        <Chip label={item.category} className="chip" sx={{ fontSize: "13px" }} />
                        <p>{item.name}</p>
                        <small>{item.posologie}</small>
                    </div>
                )
            })
        }

        return (
            <>
                <div className="informations">
                    <CloseIcon className="closeIcon" onClick={() => {
                        dispatch(setCurrentFolder())
                    }} />
                    <h1>Informations</h1>
                    <div className="informations__header">
                        <span>Début : <b>{dossierData.launch_time ? moment(dossierData.launch_time).format('HH:mm') : 'Inconnu'}</b></span>
                        <span>Transmission : <b>{dossierData.support_details.transmission_time ? moment(dossierData.support_details.transmission_time).format('HH:mm') : 'Inconnu'}</b></span>
                        <span>Géré par : <b>{dossierData.initialSinapseUser?.userFirstName ? (dossierData.initialSinapseUser.userFirstName + " " + dossierData.initialSinapseUser.userLastName + " (" + dossierData.initialSinapseUser.userType + "/" + dossierData.initialSinapseUser.instancePublicName + ")") : "Non affecté"}</b></span>
                    </div>
                    <FormControl className="informations__status" sx={{
                        position: 'absolute',
                        top: 20,
                        right: 55
                    }}>
                        <Select
                            displayEmpty
                            className="informations__status-select"
                            value={dossierData.support_details.status}
                            onChange={(event) => {
                                socket.emit("SINAPSE_FOLDER_UPDATE_STATUS", {
                                    id: dossierData.id,
                                    type: currentFolder.type,
                                    jwt: token,
                                    newStatus: event.target.value
                                }, (response) => {
                                    if (response.status) {
                                        if (response.status === 'DONE') {
                                            dispatch(setAlert({ type: "success", message: "Enregistré" }))
                                        }
                                    }
                                })
                            }}
                            sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                '&:has(input[value="NON_TRAITE"])': {
                                    backgroundColor: 'alert.main',
                                    color: 'white.main',
                                },
                                '&:has(input[value="ONGOING"])': {
                                    backgroundColor: 'warning.light',
                                    color: 'secondary.main',
                                },
                                '&:has(input[value="CLOSED"])': {
                                    backgroundColor: 'green.light',
                                    color: 'secondary.main',
                                },
                                '& .MuiSelect-select': {
                                    fontSize: 14,
                                    padding: '10px 40px 10px 15px'
                                },
                                '& svg': {
                                    width: 18
                                }
                            }}
                        >
                            <MenuItem value={'NON_TRAITE'} sx={{
                                fontSize: 14,
                                backgroundColor: 'alert.main',
                                color: 'white.main',
                                fontWeight: 500,
                                '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                    backgroundColor: 'alert.main',
                                },
                                'ul:has(> &)': {
                                    padding: 0
                                }
                            }} className="informations__status-select-nt">Non traité</MenuItem>
                            <MenuItem value={'ONGOING'} sx={{
                                fontSize: 14,
                                backgroundColor: 'warning.light',
                                color: 'secondary.main',
                                fontWeight: 500,
                                '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                    backgroundColor: 'warning.light',
                                }
                            }} className="informations__status-select-ec">En cours</MenuItem>
                            <MenuItem value={'CLOSED'} sx={{
                                fontSize: 14,
                                backgroundColor: 'green.light',
                                color: 'secondary.main',
                                fontWeight: 500,
                                '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                    backgroundColor: 'green.light',
                                }
                            }} className="informations__status-select-t">Terminé</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="informations__body">
                        <div className="informations__body-incident">
                            <div className="informations__body-incidentContainer">
                                <span className="title">Incident</span>
                                {
                                    dossierData.main_diagnosis_certainty > 0.8
                                        ? <CheckCircleIcon className="certaintyIcon" /> : ''
                                }
                                <div className="incidentHeader">
                                    <b className="incidentTitle">{
                                        dossierData.diagnosis_details.situation.name ?? "Nature de fait inconnue"
                                    }</b>
                                    <FormControl className="informations__incident-gravity">
                                        <Select
                                            displayEmpty
                                            className="informations__incident-gravity-select"
                                            value={getGravity(dossierData)}
                                            onChange={(event) => {
                                                socket.emit("SINAPSE_FOLDER_UPDATE_GRAVITY", {
                                                    id: dossierData.id,
                                                    type: currentFolder.type,
                                                    jwt: token,
                                                    newGravity: event.target.value
                                                }, (response) => {
                                                    if (response.status) {
                                                        if (response.status === 'DONE') {
                                                            dispatch(setAlert({ type: "success", message: "Enregistré" }))
                                                        }
                                                    }
                                                })
                                            }}
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                '&:has(input[value="eu"])': {
                                                    backgroundColor: 'alert.main',
                                                    color: 'white.main',
                                                },
                                                '&:has(input[value="u1"])': {
                                                    backgroundColor: 'warning.main',
                                                    color: 'secondary.main',
                                                },
                                                '&:has(input[value="u2"])': {
                                                    backgroundColor: 'warning.light',
                                                    color: 'secondary.main',
                                                },
                                                '&:has(input[value="u3"])': {
                                                    backgroundColor: 'green.light',
                                                    color: 'secondary.main',
                                                },
                                                '& .MuiSelect-select': {
                                                    fontSize: 14,
                                                    padding: '10px 40px 10px 15px'
                                                },
                                                '& svg': {
                                                    width: 18
                                                }
                                            }}
                                        >
                                            <MenuItem value={'eu'} sx={{
                                                fontSize: 14,
                                                backgroundColor: 'alert.main',
                                                color: 'white.main',
                                                fontWeight: 500,
                                                '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                                    backgroundColor: 'alert.main',
                                                },
                                                'ul:has(> &)': {
                                                    padding: 0
                                                }
                                            }} className="informations__incident-gravity-select-eu">EU</MenuItem>
                                            <MenuItem value={'u1'} sx={{
                                                fontSize: 14,
                                                backgroundColor: 'warning.main',
                                                color: 'secondary.main',
                                                fontWeight: 500,
                                                '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                                    backgroundColor: 'warning.main',
                                                }
                                            }} className="informations__incident-gravity-select-u1">U1</MenuItem>
                                            <MenuItem value={'u2'} sx={{
                                                fontSize: 14,
                                                backgroundColor: 'warning.light',
                                                color: 'secondary.main',
                                                fontWeight: 500,
                                                '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                                    backgroundColor: 'warning.light',
                                                }
                                            }} className="informations__incident-gravity-select-u2">U2</MenuItem>
                                            <MenuItem value={'u3'} sx={{
                                                fontSize: 14,
                                                backgroundColor: 'green.light',
                                                color: 'secondary.main',
                                                fontWeight: 500,
                                                '&.Mui-selected,&:hover,&.Mui-selected:hover': {
                                                    backgroundColor: 'green.light',
                                                }
                                            }} className="informations__incident-gravity-select-u3">U3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="incidentLocationData">
                                    <div>
                                        <div className="header">
                                            <MapsHomeWorkIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                            <span>Type de lieu</span>
                                        </div>
                                        <p>{
                                            dossierData.incident_type ? dossierData.incident_type :
                                                dossierData.incident_details.address.type === 'house' ? "Résidence privée" : dossierData.incident_details.address.type === 'community_centre' ? "ERP/Vie locale" : dossierData.incident_details.address.type === "events_centre" ? "ERP/Événementiel" : "Inconnu"
                                        }</p>
                                    </div>
                                    <div>
                                        <div className="header">
                                            <MapIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                            <span>Adresse</span>
                                        </div>
                                        <p>{
                                            dossierData.street ?? "Adresse inconnue"
                                        }<br />{
                                                dossierData.city ? (dossierData.city + " - " + dossierData.state) : ""
                                            }</p>
                                    </div>
                                </div>
                                <div className="incidentDescription">
                                    <span className="statusChip">{
                                        dossierData.status === "DISPLAY_GESTURES" ? "Mesures conservatoires" :
                                            dossierData.status === "QUESTIONING" ? "Questions" :
                                                dossierData.status === "SURVEILLANCE" ? "Surveillance" : "En cours"
                                    }</span>
                                    <div className="header">
                                        <MenuBookIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                        <span>Description</span>
                                    </div>

                                    <div className="incidentDescription-flex">
                                        <div>
                                            <p><b>Informations de diagnostic</b></p>
                                            {
                                                csDescriptionInfo
                                            }
                                            {
                                                valuesDescriptionInfo
                                            }
                                        </div>
                                        <div>
                                            <p><b>Gestes de premiers secours</b></p>
                                            {
                                                valuesRescueDescriptionInfo
                                            }
                                        </div>
                                    </div>

                                    {
                                        (valuesDescriptionWarnings.length || valuesDescriptionAlerts.length || csDescriptionAlerts.length || csDescriptionWarning.length || valuesRescueDescriptionAlerts.length || valuesRescueDescriptionWarning.length) ? (
                                            <Stack sx={{ width: '100%', marginTop: '20px' }} spacing={1}>
                                                {
                                                    csDescriptionAlerts
                                                }
                                                {
                                                    csDescriptionWarning
                                                }
                                                {
                                                    valuesDescriptionWarnings
                                                }
                                                {
                                                    valuesDescriptionAlerts
                                                }
                                                {
                                                    valuesRescueDescriptionAlerts
                                                }
                                                {
                                                    valuesRescueDescriptionWarning
                                                }
                                            </Stack>
                                        ) : ''
                                    }

                                </div>
                                <div className="incidentMap">
                                    <MapContainer center={dossierData.geo_lat ? [dossierData.geo_lat, dossierData.geo_lon] : ["43.345917", "1.181540"]} zoom={dossierData.geo_lat ? 16 : 8} scrollWheelZoom={true} className="incidentMap-map">
                                        <TileLayer
                                            attribution='&copy; Données <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> - SINAPSE™'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {
                                            dossierData.geo_lat ? <Marker position={[dossierData.geo_lat, dossierData.geo_lon]}>
                                                <Popup>
                                                    {
                                                        dossierData.street ?? "Adresse inconnue"
                                                    }<br />{
                                                        dossierData.city ? (dossierData.city + " - " + dossierData.state) : ""
                                                    }
                                                </Popup>
                                            </Marker> : null
                                        }

                                    </MapContainer>
                                </div>
                            </div>
                        </div>

                        <div className="informations__body-requerant">
                            <span className="title">Requérant</span>
                            {
                                (dossierData.origin === 1) ? <SmartphoneIcon className="originIcon" title="Le requérant utilise l'application EmerGa" /> :
                                    <LanguageIcon className="originIcon" title="Le requérant utilise l'application web EmerGa" />
                            }
                            {
                                isRequerant ? (
                                    <>
                                        <CheckCircleIcon className="authenticatedIcon" />
                                        <b className="requerantName">{
                                            dossierData.incident_details.userDetails.firstName + " " + dossierData.incident_details.userDetails.lastName
                                        }</b>
                                        <span className="phone">
                                            <PhoneInTalkIcon sx={{ fontSize: 20 }} />
                                            {new AsYouType('FR').input(dossierData.incident_details.userDetails.phone)}
                                        </span>
                                        {
                                            dossierData.incident_details.userDetails.userFormation.isFormed === true ? (
                                                <p className="formation">
                                                    <b>Requérant formé ({dossierData.incident_details.userDetails.userFormation.formationType})</b><br />
                                                    Le requérant indique être formé aux gestes qui sauvent et utilise l'applicatif EmerGa.
                                                </p>
                                            ) : dossierData.incident_details.userDetails.userFormation.isFormed === false ? (
                                                <p>Requérant non formé aux gestes qui sauvent.</p>
                                            ) : (<p>Statut de formation inconnu.</p>)
                                        }
                                    </>
                                ) : (
                                    <p>
                                        <b>Le requérant n'est pas authentifié.</b>
                                    </p>
                                )
                            }
                        </div>
                        <div className="informations__body-victim">
                            <span className="title">Victime</span>
                            <div className="informations__body-victim-details">


                                {
                                    valuesVictimInfo.length || csVictimInfo.length ? <>
                                        <p><b>Informations issues de l'algorithme :</b></p>
                                        {csVictimInfo}
                                        {valuesVictimInfo}
                                        <br /><br />
                                    </> : null
                                }
                                {
                                    (valuesVictimAlerts.length || valuesVictimWarnings.length || csVictimAlerts.length || csVictimWarning.length) ? (
                                        <>
                                            <Stack sx={{ width: '100%', marginTop: '20px' }} spacing={1}>
                                                {
                                                    csVictimAlerts
                                                }
                                                {
                                                    csVictimWarning
                                                }
                                                {
                                                    valuesVictimWarnings
                                                }
                                                {
                                                    valuesVictimAlerts
                                                }
                                            </Stack>
                                            <br /><br />
                                        </>
                                    ) : ''
                                }

                                {
                                    dossierData.incident_details.victimDetails ?
                                        <>
                                            <CheckCircleIcon className="authenticatedIcon" />
                                            <div className='identityData'>
                                                <div className="header">
                                                    <PersonIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                                    <span>Victime authentifiée</span>
                                                </div>
                                                Femme, {victimAge}<br />
                                                Réside à {dossierData.incident_details.victimDetails.city}
                                            </div>
                                            <div className="antecedentsData">
                                                <div className="header">
                                                    <AccessTimeIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                                    <span>Antécédents</span>
                                                </div>
                                                {antecedents}
                                            </div>
                                            <div className="antecedentsData">
                                                <div className="header">
                                                    <FamilyRestroomIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                                    <span>Antécédents familiaux</span>
                                                </div>
                                                {antecedentsFamiliaux}
                                            </div>
                                            <div className="pathologiesData">
                                                <div className="header">
                                                    <ThermostatIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                                    <span>Pathologies</span>
                                                </div>
                                                {pathologies}
                                            </div>

                                            <div className="pathologiesData">
                                                <div className="header">
                                                    <VaccinesIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                                    <span>Traitements</span>
                                                </div>
                                                {traitements}
                                            </div>

                                            <div className="contactData">
                                                <div className="header">
                                                    <SupportIcon sx={{ fontSize: 20, color: 'secondary.grey700' }} />
                                                    <span>Contact d'urgence</span>
                                                </div>
                                                <p>{dossierData.incident_details.victimDetails.emergencyContact.name}</p>
                                                <small style={{ fontStyle: 'italic' }}><a href={"tel:" + dossierData.incident_details.victimDetails.emergencyContact.phone}>
                                                    {new AsYouType('FR').input(dossierData.incident_details.victimDetails.emergencyContact.phone)}
                                                </a></small>
                                            </div>
                                        </>

                                        : <b>La victime n'est pas authentifiée.</b>
                                }
                            </div>
                        </div>

                        <div className="informations__body-emerga">
                            <span className="title">EmerGa</span>
                            <div className='informations__section-slider'>
                                <p>Récupérez des informations et échangez avec le requérant.</p>

                                <OutlinedButton style={{ marginBottom: "25px", marginTop: "15px" }} filled fullwidth onClick={() => { openGestureModal(true) }}><HealingIcon sx={{ fontSize: 20 }} />Afficher un geste d'urgence</OutlinedButton>

                                <b>Consigne personnalisée</b>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 50px", gap: "5px", width: "100%", marginTop: "5px", alignItems: "flex-start" }}>
                                    <OutlinedInput
                                        type="text"
                                        placeholder="Saisissez une consigne"
                                        className='search'
                                        multiline
                                        value={consigneTerms}
                                        onChange={(t) => {
                                            setConsigneTerms(t.target.value)
                                        }}
                                    />
                                    <OutlinedButton onClick={() => {
                                        socket.emit("SINAPSE_SEND_INSTRUCTION", {
                                            id: dossierData.id,
                                            type: currentFolder.type,
                                            jwt: token,
                                            instruction: consigneTerms,
                                        }, (response) => {
                                            if (response.status) {
                                                if (response.status === 'DONE') {
                                                    dispatch(setAlert({ type: "success", message: "Enregistré" }))
                                                }
                                            }
                                        })
                                    }}><SendIcon sx={{ fontSize: 20 }} /></OutlinedButton>
                                </div>
                            </div>
                        </div>

                        <div className="informations__body-actions">
                            <OutlinedButton fullwidth whiteFilled leftIcon>
                                <PhotoCameraIcon sx={{ fontSize: 20 }} />Demander une photographie
                            </OutlinedButton>
                            <OutlinedButton href={dossierData.rescuer_phone ? "tel:" + dossierData.rescuer_phone : "#"} fullwidth whiteFilled leftIcon>
                                <CallIcon sx={{ fontSize: 20 }} />Lancer un appel téléphonique
                            </OutlinedButton>
                            <OutlinedButton fullwidth whiteFilled leftIcon onClick={() => { openVideoCallModal(true) }}>
                                <VideoCallIcon sx={{ fontSize: 20 }} />Lancer un appel vidéo
                            </OutlinedButton>
                            <OutlinedButton fullwidth whiteFilled leftIcon>
                                <GpsFixedIcon sx={{ fontSize: 20 }} />Demander une géolocalisation
                            </OutlinedButton>
                        </div>

                        <div className="informations__body-dossier">
                            <div className='observations'>
                                <span className="title">Observations</span>
                                <div style={{
                                    display: "grid",
                                    gap: "10px",
                                    gridTemplateColumns: "1fr 50px",
                                    width: "100%"
                                }}>
                                    <OutlinedInput
                                        type="text"
                                        placeholder="Saisissez une observation"
                                        className='search'
                                        multiline
                                        sx={{
                                            height: "75px", textAlign: "flex-start",
                                            inputMultiline: {
                                                height: "45px"
                                            }
                                        }}
                                        value={observationTerms}
                                        onChange={(t) => {
                                            setObservationTerms(t.target.value)
                                        }}
                                    />
                                    <OutlinedButton onClick={() => {
                                        socket.emit("SINAPSE_ADD_OBSERVATION", {
                                            id: dossierData.id,
                                            type: currentFolder.type,
                                            jwt: token,
                                            observation: observationTerms,
                                        }, (response) => {
                                            if (response.status) {
                                                if (response.status === 'DONE') {
                                                    dispatch(setAlert({ type: "success", message: "Enregistré" }))
                                                }
                                            }
                                        })
                                    }}><SendIcon sx={{ fontSize: 20 }} /></OutlinedButton>
                                </div>

                                <div className='observations-history'>
                                    <span className="title">Historique des observations continues</span>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "10px",
                                    }}>
                                        {observationsContinues}
                                    </div>
                                </div>
                            </div>
                            <div className='operations'>
                                <div className='container'>
                                    <p>Associer à une affaire :</p>
                                    <FormControl sx={{
                                        margin: "3px 0 0 0", minWidth: 120
                                    }} size="small">
                                        <Select
                                            value={"NOAFFAIR"}
                                            sx={{
                                                select: {
                                                    fontSize: "15px", backgroundColor: "#ffffff"
                                                }, backgroundColor: "#ffffff", fontSize: "15px"
                                            }}
                                        >
                                            <MenuItem disabled value="NOAFFAIR">
                                                <em>Aucune affaire sélectionnée</em>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>


                                <div className='container'>
                                    <p>Transmettre :</p>
                                    <FormControl sx={{ margin: "3px 0 0 0", minWidth: 120, backgroundColor: "#ffffff" }} size="small">
                                        <Select
                                            value='ARM'
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    <Chip key={"ARM"} label={"ARM"} />
                                                </Box>
                                            )}
                                        >
                                            <MenuItem value="ARM" selected>
                                                <Checkbox checked={true} />
                                                <ListItemText primary="ARM" />
                                            </MenuItem>
                                            <MenuItem value="MR">
                                                <Checkbox />
                                                <ListItemText primary="MR" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='container'>
                                    <p>Réaffecter :</p>
                                    <FormControl sx={{ margin: "3px 0 0 0", minWidth: 120, backgroundColor: "#ffffff" }} size="small">
                                        <Select
                                            value='SAMU31'
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    <Chip key={"CRRA 31"} label={"CRRA 31"} />
                                                </Box>
                                            )}
                                        >
                                            <MenuItem value="SAMU31" selected>
                                                <Checkbox checked={true} />
                                                <ListItemText primary="CRRA 31" />
                                            </MenuItem>
                                            <MenuItem value="SDIS31" selected>
                                                <Checkbox checked={false} />
                                                <ListItemText primary="CTA-CODIS 31" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Backdrop open={gestureModalOpened} sx={{ zIndex: 500 }}>
                    <div className='modal'>
                        <CloseIcon className="close" onClick={() => { openGestureModal(false) }} />
                        <h3>Afficher un geste d'urgence</h3>
                        <p>Sélectionnez un geste d'urgence à afficher sur l'écran du requérant pour l'aider à mettre en œuvre les mesures conservatoires appropriées.</p>

                        <OutlinedInput
                            type="text"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            sx={{ margin: "25px 0 25px 0" }}
                            placeholder="Incident, geste d'urgence..."
                            className='search'
                            value={searchTermsGestures}
                            onChange={(t) => {
                                setSearchTermsGestures(t.target.value)
                                sortProposedSituations(t.target.value, situations, setActiveSituations)
                            }}
                        />

                        <div className='situationsTable-container'>
                            <table className='situationsTable'>
                                <colgroup>
                                    <col span="1" style={{ width: '40%' }} />
                                    <col span="1" style={{ width: '40%' }} />
                                    <col span="1" style={{ width: '20%' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className='situationColumn'>Situation</th>
                                        <th className='gestureColumn'>Mesure conservatoire</th>
                                        <th className='actionColumn'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        activeSituations
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Backdrop>

                <Backdrop open={videoCallModal} sx={{ zIndex: 500 }}>
                    <div className='modal'>
                        <CloseIcon className="close" onClick={() => { openVideoCallModal(false) }} />
                        <h3>Démarrez un appel vidéo</h3>
                    </div>
                </Backdrop>

            </>
        )
    }
}

export { Informations };