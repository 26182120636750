import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import Store from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { CustomComponentStyle } from "./utils"

import STYLE from "./style/main.css"

import SINAPSE from './SINAPSE'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <React.StrictMode>
            <ThemeProvider theme={CustomComponentStyle}>
                <Router>
                    <SINAPSE />
                </Router>
            </ThemeProvider>
        </React.StrictMode>
    </Provider >
);