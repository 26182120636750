import logoSinapse from "../assets/SINAPSE.png"

import { useDispatch } from "react-redux";
import { setRunning, loadUserInformations, setError } from "../store"
import { useState, useEffect } from "react";

import axios from "axios";
import { APILinks } from "../utils/PageLinks";

const Splash = () => {

    const dispatch = useDispatch();
    const [alreadyFetched, setAlreadyFetched] = useState(false)

    useEffect(() => {
        var jwt = sessionStorage.getItem('jwt');

        if (jwt && !alreadyFetched) {

            setAlreadyFetched(true)
            axios.post(APILinks.login, {
                jwt: jwt
            }).then(result => {
                if (result.data.ack === "SUCCESS") {
                    dispatch(loadUserInformations({
                        userInformations: {
                            ...result.data.userInformations
                        },
                        instanceInformations: {
                            ...result.data.instanceInformations
                        },
                        token: result.data.token,
                    }))
                } else {
                    dispatch(setError({ message: "Vous n'êtes plus authentifié(e).", active: true }))
                }
                dispatch(setRunning(true))
            }).catch(err => {
                console.log(err)
                dispatch(setError({ message: "Vous n'êtes plus authentifié(e).", active: true }))
                dispatch(setRunning(true))
            })
        } else {
            dispatch(setRunning(true))
        }
    }, [alreadyFetched])



    return (<div className="splash">
        <img src={logoSinapse} alt="SINAPSE™" className="splash__logo" />
    </div>)
}

export { Splash }