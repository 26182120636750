const SynoptiqueStatusChip = ({ status }) => {

    let statusContent = { class: '', title: "" }

    if (status === "NON_TRAITE") {
        statusContent.title = "Non traité"
        statusContent.class = 'nonTraite'
    } else if (status === "ONGOING") {
        statusContent.title = "En cours"
        statusContent.class = 'enCours'
    } else if (status === "CLOSED") {
        statusContent.title = "Terminé"
        statusContent.class = 'closed'
    } else if (status === "TRANSMITTED") {
        statusContent.title = "Transmis"
        statusContent.class = 'transmitted'
    }

    return (
        <div className={statusContent.class + ' statusChip'}>
            <i></i>
            <span>{statusContent.title}</span>
        </div>
    )
}

export default SynoptiqueStatusChip;