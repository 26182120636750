import { setDossiers, setParsedDossiers, setDisplayedFolders } from "../store"

const ParseFolders = (data, dispatch, userInformations) => {
    var returnData = {
        serviceUrgences: {

        },
        userUrgences: {

        },
        serviceManualTreatments: {

        },
        userManualTreatments: {

        },
        serviceAffairs: {

        },
        userAffairs: {

        },
        alerts: {

        },
        pastUserFolders: {},
        pastServiceFolders: {}
    }

    Object.entries(data.urgences).forEach(([id, urgence]) => {
        if (!urgence.sinapseUsers.length) {
            returnData.alerts[id] = urgence
        }
        Object.entries(urgence.sinapseUsers).forEach(([index, user]) => {
            if (user.userId === userInformations.id) {
                if (urgence.support_details.status === "CLOSED") {
                    returnData.pastUserFolders["U" + id] = urgence
                } else {
                    returnData.userUrgences[id] = urgence
                }
            }
        })
        if (urgence.support_details.status === "CLOSED") {
            returnData.pastServiceFolders["U" + id] = urgence
        } else {
            returnData.serviceUrgences[id] = urgence
        }
    })

    Object.entries(data.manualTreatments).forEach(([id, manualTreatment]) => {
        Object.entries(manualTreatment.sinapseUsers).forEach(([index, user]) => {
            if (user.userId === userInformations.id) {
                if (manualTreatment.support_details.status === "CLOSED") {
                    returnData.pastUserFolders["MT" + id] = manualTreatment
                } else {
                    returnData.userManualTreatments[id] = manualTreatment
                }
            }
        })
        if (manualTreatment.support_details.status === "CLOSED") {
            returnData.pastServiceFolders["MT" + id] = manualTreatment
        } else {
            returnData.serviceManualTreatments[id] = manualTreatment
        }
    })

    Object.entries(data.affairs).forEach(([id, affair]) => {
        affair.sinapseUsers.forEach((v, i) => {
            if (v.userId === userInformations.id) {
                returnData.userAffairs[id] = affair
            }
        })
        returnData.serviceAffairs[id] = affair
    })

    dispatch(setDossiers(data))
    dispatch(setParsedDossiers(returnData))
    dispatch(setDisplayedFolders(returnData));
    console.log(returnData)
    return true;
}

const getGravity = (data, isAffair = false) => {

    let us;
    let gravity = null;

    if (!isAffair) {
        us = (data.urgency + data.severity) / 2
    } else {
        us = (data.linkedFolders[0]?.urgency + data.linkedFolders[0]?.severity) / 2
    }

    if (us >= 0.8999) {
        gravity = "eu"
    } else if (us >= 0.8) {
        gravity = "u1"
    } else if (us >= 0.6) {
        gravity = "u2"
    } else {
        gravity = "u3"
    }
    return gravity
}

export { ParseFolders, getGravity }